export const searchTree = {
  Media: [
    "Audio",
    "Podcast",
    "Producer",
    "Camera Operator",
    "Producer",
    "Director",
    "Writer",
    "Journalist",
    "Content Writer",
    "Copy Writer",
    "Translator",
  ],
  Finance: ["Accountant", "Financial Controller", "Auditor"],
  Technology: [
    "Web Engineer",
    "Test Engineer",
    "Embedded Engineer",
    "Data Engineer",
    "Machine Learning Engineer",
  ],
  Law: ["Lawyer", "Legal Consultant"],
  "Product/Design": [
    "Product Designer",
    "Product Manager",
    "Product Owner",
    "Technical Programme Manager",
  ],
  Marketing: ["Product Marketer", "SEO Manager"],
  Sales: ["Salesperson", "Account Manager"],
  "Customer Service": [
    "Customer Service Person",
    "Support Person",
    "Customer Advisor",
  ],
  Education: [
    "Pre-school Teacher",
    "Elementary Teacher",
    "High-school Teacher",
    "Professor",
  ],
  Engineering: [
    "Mechanical Engineer",
    "Aerospace Engineer",
    "Chemical Engineer",
    "Marine Engineer",
    "Civil Engineer",
    "Architect",
  ],
  Medicine: ["Doctor", "Nurse"],
};

export const getCategorySEOText = (categoryTitle: string) => {
  const categoryTexts = {
    Media:
      "Explore exciting media jobs in Finland, perfect for English-speaking professionals. Find roles in television, film, journalism, and more, with top employers hiring today.",
    Audio:
      "Discover audio jobs in Finland for sound engineers, audio editors, and production specialists. Work on exciting projects with industry leaders in the Finnish media scene.",
    Podcast:
      "Find podcast-related jobs in Finland, including hosting, editing, and production roles. Join the growing podcast industry in a vibrant and creative market.",
    Producer:
      "Explore producer jobs in Finland for English speakers. Lead exciting media, film, or content creation projects with top Finnish employers.",
    "Camera Operator":
      "Search for camera operator jobs in Finland. Work in film, television, or live production settings with great opportunities for growth.",
    Director:
      "Find director jobs in Finland for film, media, or creative projects. Lead high-impact productions and showcase your skills in a thriving industry.",
    Writer:
      "Explore writer jobs in Finland for content creators, journalists, and creative professionals. Write for global audiences with leading companies.",
    Journalist:
      "Find journalist jobs in Finland for English speakers. Cover local and international stories in print, broadcast, or online media.",
    "Content Writer":
      "Search content writer jobs in Finland. Create engaging articles, blogs, or marketing content for diverse industries.",
    "Copy Writer":
      "Explore copywriter jobs in Finland for English speakers. Write persuasive marketing and advertising copy for top companies.",
    Translator:
      "Find translator jobs in Finland. Work with global businesses to bridge language gaps and deliver high-quality translations.",
    Finance:
      "Explore finance jobs in Finland for accountants, auditors, and financial analysts. Work with leading companies to grow your career.",
    Accountant:
      "Search accountant jobs in Finland. Join reputable firms or companies needing skilled professionals in finance and accounting.",
    "Financial Controller":
      "Find financial controller jobs in Finland. Oversee company finances and make an impact in top-level roles.",
    Auditor:
      "Discover auditor jobs in Finland. Help businesses stay compliant while advancing your auditing career.",
    Technology:
      "Explore technology jobs in Finland, including roles in software development, IT, and AI. Work with cutting-edge companies leading innovation.",
    "Web Engineer":
      "Search web engineer jobs in Finland. Build and maintain innovative websites and applications with leading tech firms.",
    "Test Engineer":
      "Find test engineer jobs in Finland. Ensure the quality of software, products, and systems for top employers.",
    "Embedded Engineer":
      "Explore embedded engineer jobs in Finland. Design, develop, and test embedded systems in high-tech industries.",
    "Data Engineer":
      "Find data engineer jobs in Finland. Work on data pipelines and analytics for global and local businesses.",
    "Machine Learning Engineer":
      "Discover machine learning engineer jobs in Finland. Build AI solutions and work with innovative companies.",
    Law: "Search law jobs in Finland, including roles for lawyers, legal consultants, and corporate counsel. Work in top law firms or in-house teams.",
    Lawyer:
      "Find lawyer jobs in Finland for English-speaking professionals. Provide legal expertise in a dynamic market.",
    "Legal Consultant":
      "Explore legal consultant jobs in Finland. Advise businesses and individuals on key legal matters.",
    "Product/Design":
      "Discover product and design jobs in Finland for English speakers. Shape user experiences and lead product strategies.",
    "Product Designer":
      "Search product designer jobs in Finland. Create user-centered designs for innovative companies.",
    "Product Manager":
      "Find product manager jobs in Finland. Lead product development and drive business success in top companies.",
    "Product Owner":
      "Explore product owner jobs in Finland. Oversee agile development teams and deliver top-tier products.",
    "Technical Programme Manager":
      "Search technical programme manager jobs in Finland. Coordinate complex projects and work with leading teams.",
    Marketing:
      "Discover marketing jobs in Finland for English speakers. Create strategies and campaigns to grow brands.",
    "Product Marketer":
      "Find product marketer jobs in Finland. Drive marketing strategies and product awareness in tech and beyond.",
    "SEO Manager":
      "Explore SEO manager jobs in Finland. Optimize web content and lead strategies to improve search rankings.",
    Sales:
      "Search sales jobs in Finland for English speakers. Find roles in B2B, tech sales, and account management.",
    Salesperson:
      "Discover salesperson jobs in Finland. Work in dynamic sales environments and grow your career.",
    "Account Manager":
      "Find account manager jobs in Finland. Manage client relationships and drive business success.",
    "Customer Service":
      "Search customer service jobs in Finland. Assist customers in diverse industries with top employers.",
    "Customer Service Person":
      "Discover customer service jobs in Finland. Support customers and build a career in service roles.",
    "Support Person":
      "Find support person jobs in Finland. Provide help desk and IT support for leading companies.",
    "Customer Advisor":
      "Explore customer advisor jobs in Finland. Guide customers and ensure excellent service experiences.",
    Education:
      "Discover education jobs in Finland for teachers and academic professionals. Work in schools, universities, and training institutions.",
    "Pre-school Teacher":
      "Search pre-school teacher jobs in Finland. Nurture young learners in a rewarding teaching environment.",
    "Elementary Teacher":
      "Find elementary teacher jobs in Finland. Teach and inspire students in dynamic classroom settings.",
    "High-school Teacher":
      "Explore high-school teacher jobs in Finland. Shape the future of students with engaging lessons.",
    Professor:
      "Discover professor jobs in Finland. Lead research and teaching in prestigious universities.",
    Engineering:
      "Search engineering jobs in Finland for mechanical, civil, and chemical engineers. Work on exciting projects in top companies.",
    "Mechanical Engineer":
      "Find mechanical engineer jobs in Finland. Design and develop innovative mechanical systems.",
    "Aerospace Engineer":
      "Explore aerospace engineer jobs in Finland. Work in cutting-edge aviation and space industries.",
    "Chemical Engineer":
      "Search chemical engineer jobs in Finland. Develop processes and products in the chemical sector.",
    "Marine Engineer":
      "Discover marine engineer jobs in Finland. Work on ships, ports, and maritime technologies.",
    "Civil Engineer":
      "Find civil engineer jobs in Finland. Build infrastructure projects and shape the built environment.",
    Architect:
      "Explore architect jobs in Finland. Design innovative buildings and urban spaces.",
    Medicine:
      "Search medicine jobs in Finland for doctors, nurses, and healthcare professionals. Work in hospitals and clinics.",
    Doctor:
      "Discover doctor jobs in Finland. Provide expert medical care in a supportive healthcare system.",
    Nurse:
      "Find nurse jobs in Finland. Deliver patient care and grow your career in Finnish healthcare.",
  };
  return categoryTexts[categoryTitle] ? categoryTexts[categoryTitle] : null;
};

// TODO:: Create a tree structure that can be traversed by the function to grab all the leaves of a given leaf
export const getTreeLevel = (category: string) => {
  let level = category;
  while (searchTree[level] && typeof searchTree[level] === "object") {
    level = searchTree[level];
  }
  return searchTree[level];
};
