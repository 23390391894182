export function addToViewedJobs(url: string): void {
  const existingJobURLs: string[] = JSON.parse(
    localStorage.getItem("viewedJobs") ?? "[]"
  );
  const newJobsURLsList: string[] = [...existingJobURLs, url];
  localStorage.setItem("viewedJobs", JSON.stringify(newJobsURLsList));
}

export function setViewedJobs(jobs: Array<string>) {
  localStorage.setItem("viewedJobs", JSON.stringify(jobs));
}
