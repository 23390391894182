import { JobSearchParams } from "../../types.ts";
import { fromKebabCaseToLocation, splitOr } from "../helpers.ts";

export function parseSeoPath(
  path: string,
  locale: string | undefined
): JobSearchParams {
  if (path.startsWith("/")) {
    path = path.slice(1);
  }

  const tokens: string[] = path.split("-");
  // We'll build the result as we go
  const result: JobSearchParams = {
    remoteness: [],
    normalisedRoleLevel: [],
    requiredLanguages: [],
    industry: [],
    company: [],
    locations: [],
    query: undefined,
    page: undefined,
  };

  // 1) The first token should be "find"
  if (tokens[0] !== "find") {
    // Not a valid format, return empty result or handle error
    return result;
  }
  tokens.shift(); // remove "find"

  // We now expect a sequence of up to 6 “optional” segments
  // (remoteness, normalisedRoleLevel, requiredLanguages, industry, company, query)
  // terminated by "jobs".
  //
  // We'll process them in order. For each slot, we check if tokens[0] has "or"
  // AND it's known to match our domain for that slot. If so, parse it as array.
  // Otherwise, we see if it's "jobs". If it's "jobs", we proceed further.
  // If it's none of the above, treat it as the next slot or skip, etc.
  //
  // A simpler approach: we'll define an array describing these slots.
  // We'll try to fill them in order until we reach "jobs".

  // Helper to see if all items in an array are in a given set
  function allInSet(items: string[], validSet: Set<string>): boolean {
    return items.every((i) => validSet.has(i));
  }

  const remotenessSet = new Set(["Remote", "Onsite", "Hybrid"]);
  const roleLevelSet = new Set([
    "Junior",
    "Mid-Level",
    "Senior",
    "Expert",
    "Director",
    "Manager",
    "Executive",
    "Intern",
    //TODO:: Remove these two options
    "Assistant",
    "Entry Level",
  ]);
  // For languages, industry, company, query, we won't strictly validate
  // because they can be anything.

  // We'll define the order of fields we expect in front of "jobs":
  const fieldOrder: Array<{
    key: keyof JobSearchParams;
    validSet?: Set<string>;
  }> = [
    { key: "remoteness", validSet: remotenessSet },
    { key: "normalisedRoleLevel", validSet: roleLevelSet },
    { key: "requiredLanguages" },
    { key: "industry" },
    { key: "company" },
    { key: "query" },
  ];

  for (const field of fieldOrder) {
    // If we've already run out of tokens or the next token is "jobs", break out
    if (tokens.length === 0 || tokens[0] === "jobs") {
      break;
    }

    const token = tokens[0];

    // For arrays, we look if it has "-or-" in it
    const hasOr = token.includes("-eller-");
    let splitted: string[];

    if (hasOr) {
      splitted = splitOr(token, locale);
      // If there's a validSet, check if all splitted items are in that set
      if (field.validSet) {
        const isAllValid = allInSet(splitted, field.validSet);
        if (isAllValid) {
          // Good, we parse it as an array for that field
          (result[field.key] as string[]) = splitted;
          tokens.shift();
        } else {
          // If not all valid, we might treat it as the next field or ignore
          // For simplicity, let's just break
          break;
        }
      } else {
        // No validSet, so it can be any string
        // For "query", though, do we allow multiple splitted?
        // Actually "query" typically wouldn't have '-or-' meaning.
        // But let's just store them as an array if the field is an array,
        // or join them if it's query. We'll do a quick check:
        if (Array.isArray(result[field.key])) {
          (result[field.key] as string[]) = splitted;
        } else {
          // If it's query, store it as the joined string
          result[field.key] = splitted.join(" ");
        }
        tokens.shift();
      }
    } else {
      // No "-or-", so it's a single token
      // If there's a validSet, check if it’s valid
      if (field.validSet) {
        if (field.validSet.has(token)) {
          // Perfect, we parse it as a single array
          (result[field.key] as string[]) = [token];
          tokens.shift();
        } else {
          // Not valid, break
          break;
        }
      } else {
        // If there's no validSet, it might be an array field or a string field
        if (Array.isArray(result[field.key])) {
          (result[field.key] as string[]) = [token];
        } else {
          // e.g. query => put it in
          result[field.key] = token.replace(/-/g, " ");
        }
        tokens.shift();
      }
    }
  }

  // Now we expect the token "jobs"
  if (tokens[0] === "jobs") {
    tokens.shift();
  }

  // Next, if the next token is "in", parse locations
  if (tokens[0] === "in") {
    tokens.shift();
    if (tokens.length > 0) {
      // e.g. "Helsinki-Finland-or-Tampere-Finland"
      const locToken = tokens.shift()!;
      const locChunks = splitOr(locToken, locale);
      result.locations = locChunks.map(fromKebabCaseToLocation);
    }
  }

  // Finally, if there's a token "page", parse page number
  // The path might look like:  ...-page-2
  // So if tokens[0] is "page", then tokens[1] should be the page number
  if (tokens[0] === "page") {
    tokens.shift(); // remove "page"
    if (tokens.length > 0) {
      const pageNum = parseInt(tokens.shift()!, 10);
      if (!isNaN(pageNum)) {
        result.page = pageNum;
      }
    }
  }

  // We’re done. Return whatever we parsed out
  // If the user wanted to store query as a single string,
  // watch out for tokens that might have embedded '-'.
  // We simplified that logic above.
  //
  // Finally, if `query` is a string array by accident, we might want to join it.
  // But we set up the interface for `query?: string`, so we handle that carefully.
  if (Array.isArray(result.query)) {
    // In case we inadvertently stored an array
    result.query = (result.query as string[]).join(" ");
  }

  return result;
}
