import { JobSearchParams } from "../../types";
import { fromKebabCaseToLocation, splitOr } from "../helpers";

/**
 * Parses a Swedish SEO path back into `JobSearchParams`.
 * Matches the tokens used in `buildSeoPathSv`.
 *
 * - First token: "hitta"
 * - Then optional segments
 * - Then "jobb"
 * - Then optional "i" + locations
 * - Then optional "sida" + pageNumber
 */
//TODO:: Make this parsing actually work, to do that we need the actual sets of search strings
export function parseSeoPath(
  path: string,
  locale: string | undefined
): JobSearchParams {
  if (path.startsWith("/")) {
    path = path.slice(1);
  }

  const tokens = path.split("-");
  const result: JobSearchParams = {
    remoteness: [],
    normalisedRoleLevel: [],
    requiredLanguages: [],
    industry: [],
    company: [],
    locations: [],
    query: undefined,
    page: undefined,
  };

  // 1) Must start with "hitta"
  if (tokens[0] !== "hitta") {
    return result;
  }
  tokens.shift(); // remove "hitta"

  // 2) Loop or replicate the same logic from English parse for the optional segments
  //    until we find "jobb".
  //    Below is a placeholder break. Expand this if you want to parse
  //    remoteness, roleLevel, etc. identically to your English logic.
  while (tokens.length > 0 && tokens[0] !== "jobb") {
    // Insert logic here if you want to parse these tokens in detail.
    break;
  }

  // 3) "jobb" token
  if (tokens[0] === "jobb") {
    tokens.shift();
  }

  // 4) If next token is "i", parse locations
  if (tokens[0] === "i") {
    tokens.shift();
    if (tokens.length > 0) {
      const locToken = tokens.shift()!;
      const locChunks = splitOr(locToken, locale);
      result.locations = locChunks.map(fromKebabCaseToLocation);
    }
  }

  // 5) If next token is "sida", parse page
  if (tokens[0] === "sida") {
    tokens.shift();
    if (tokens.length > 0) {
      const pageNum = parseInt(tokens.shift()!, 10);
      if (!isNaN(pageNum)) {
        result.page = pageNum;
      }
    }
  }

  return result;
}
