import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { SearchWrapper } from "../components/SearchWrapper.tsx";

const NotFound: React.FC = () => {
  const intl = useIntl();
  return (
    <SearchWrapper>
      <div className="flex flex-col items-center justify-center min-h-screen text-center bg-gray-50">
        <h1 className="text-5xl font-bold mb-6">
          <FormattedMessage
            id="notFound.title"
            defaultMessage="404: Page Not Found"
          />
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Oops! We couldn&apos;t find what you were looking for.
        </p>
        <Link
          to={`/${intl.locale}`}
          className="px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
        >
          <FormattedMessage
            id="notFound.backToHome"
            defaultMessage="Back to Home"
          />
        </Link>
      </div>
    </SearchWrapper>
  );
};

export default NotFound;
