import { JobSearchParams } from "../../types";
import { fromKebabCaseToLocation, splitOr } from "../helpers";

/**
 * Parses a Finnish SEO path back into `JobSearchParams`.
 * Matches the tokens used in `buildSeoPathFi`.
 *
 * - First token: "etsi"
 * - Then optional segments (remoteness, roleLevel, languages, etc.)
 * - Then "tyopaikat"
 * - Then optional "kohteessa" + locations
 * - Then optional "sivu" + pageNumber
 */
//TODO:: Make this parsing actually work, to do that we need the actual sets of search strings
export function parseSeoPath(
  path: string,
  locale: string | undefined
): JobSearchParams {
  // Remove leading slash if any
  if (path.startsWith("/")) {
    path = path.slice(1);
  }

  const tokens: string[] = path.split("-");
  const result: JobSearchParams = {
    remoteness: [],
    normalisedRoleLevel: [],
    requiredLanguages: [],
    industry: [],
    company: [],
    locations: [],
    query: undefined,
    page: undefined,
  };

  // 1) The first token must be "etsi"
  if (tokens[0] !== "etsi") {
    // not valid format
    return result;
  }
  tokens.shift(); // remove "etsi"

  // 2) Then we parse up to 6 optional segments, until we see "tyopaikat".
  //    The logic is the same as in your English version, just checking new tokens.
  while (tokens.length > 0 && tokens[0] !== "tyopaikat") {
    const token = tokens[0];
    // Attempt your set logic or just store them in the correct fields.
    // This logic can be as strict or lenient as your English version.
    // For brevity, let's do a simpler approach: if it's got "-or-", store it
    // in the next available array field. Otherwise, store it as a single token in the next array/string field.
    // For a fully robust solution, replicate the step-by-step checking from your English parse.

    // We'll just break if we see something not recognized.
    // If you want identical logic, copy that from your original parse function
    // and just rename the stop token from "jobs" to "tyopaikat".

    break; // placeholder — adapt from your English logic if you want the same approach
  }

  // 3) Now we expect "tyopaikat"
  if (tokens[0] === "tyopaikat") {
    tokens.shift();
  }

  // 4) If next token is "kohteessa", parse the location
  if (tokens[0] === "kohteessa") {
    tokens.shift();
    if (tokens.length > 0) {
      const locToken = tokens.shift()!;
      const locChunks = splitOr(locToken, locale);
      result.locations = locChunks.map(fromKebabCaseToLocation);
    }
  }

  // 5) If we see "sivu", parse page number
  if (tokens[0] === "sivu") {
    tokens.shift();
    if (tokens.length > 0) {
      const pageNum = parseInt(tokens.shift()!, 10);
      if (!isNaN(pageNum)) {
        result.page = pageNum;
      }
    }
  }

  return result;
}
