import "instantsearch.css/themes/satellite.css";
import { Hits, Configure, Pagination, Stats } from "react-instantsearch";
import { SearchWrapper } from "../components/SearchWrapper";
import { QuickSearchBanner } from "../components/QuickSearchBanner";
import { SEOText } from "../components/SEOText";
import { Hit } from "../components/Hit";
import { Helmet } from "react-helmet-async";
import {
  changeLanguage,
  extractLocaleSegment,
} from "../generateURLs/helpers.ts";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useIntl } from "react-intl";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default function Home() {
  const intl = useIntl();
  const location = useLocation();
  const convertPathToTitle = (path: string) => {
    const { remainder } = extractLocaleSegment(path);
    const title = remainder.replace(/-/g, " ");
    return title.charAt(0).toUpperCase() + title.slice(1);
  };
  let title = convertPathToTitle(location.pathname);

  useEffect(() => {
    title = convertPathToTitle(location.pathname);
  }, [location]);

  return (
    <SearchWrapper>
      <>
        <Helmet>
          <title>{title ? title + " | " : ""}JobCrawls.com</title>
          {title && <meta name="description" content={title} />}
          {<meta name="keywords" content={title.split(" ").join(", ")} />}
          <link rel="canonical" href={window.location.href} />
          <link
            rel="alternate"
            hrefLang="en"
            href={changeLanguage(intl.locale, "en", window.location)}
          />
          <link
            rel="alternate"
            hrefLang="fi"
            href={changeLanguage(intl.locale, "fi", window.location)}
          />
          <link
            rel="alternate"
            hrefLang="sv"
            href={changeLanguage(intl.locale, "sv", window.location)}
          />
        </Helmet>
        <div className="bg-white dark:bg-gray-700">
          <Configure hitsPerPage={25} />
          {/* TODO:: Setup bread crumbs to follow the job hierarchy so people can go back */}
          {/*<Breadcrumb*/}
          {/*  attributes={["categories.lvl0", "categories.lvl1", "categories.lvl2"]}*/}
          {/*/>*/}
          {/* TODO:: create sorts so people can sort by common sorts */}
          <div className="w-full px-0 lg:px-12 bg-white dark:bg-gray-700 pt-32 xl:pt-20 m-auto max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-5xl">
            {/*<SortBy*/}
            {/*  items={[*/}
            {/*    { label: 'Featured', value: 'text_search_index' },*/}
            {/*    { label: 'Date Posted (desc )', value: 'text_search_index_posted_date_desc' },*/}
            {/*    { label: 'Application Date (asc)', value: 'text_search_index_application_date_asc' },*/}
            {/*  ]}*/}
            {/*/>*/}
            <SEOText />
            <QuickSearchBanner />
            <div className="ais-InstantSearch w-full bg-white dark:bg-gray-700 m-auto max-w-5xl">
              <div className="text-black dark:text-white pl-4">
                <Stats />
              </div>
              {/* TODO:: Change hit component class so it can show dark and light */}
              <Hits hitComponent={Hit} />
            </div>
            <div className="w-full pt-0 lg:pt-8 pb-8 items-center justify-center ml-0 md:ml-8 lg:ml-16 xl:ml-32">
              {/* TODO:: Find a way to center this */}
              <Pagination padding={isMobile ? 1 : 3} />
            </div>
          </div>
        </div>
      </>
    </SearchWrapper>
  );
}
