import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-2FNT5F8TNE");
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Routes>
          {/*
              1) Catch a language param with path="/:locale/*"
              2) Render the <App /> which uses react-intl based on the locale param
            */}
          <Route path=":locale/*" element={<App />} />
          <Route path="/" element={<App />} />

          {/*
              3) If no language segment is provided in the URL,
                 we redirect to the default locale (e.g., /en).
            */}
          {/*<Route path="*" element={<Navigate to="/en/" replace />} />*/}
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);
