import { useAuth0 } from "@auth0/auth0-react";
import { SearchWrapper } from "../components/SearchWrapper.tsx";

export default function AuthCallback() {
  const { error } = useAuth0();

  if (error) {
    return (
      <SearchWrapper>
        <main>
          <div className="pt-28"> {error.message} </div>
        </main>
      </SearchWrapper>
    );
  }
  return (
    <SearchWrapper>
      <main>
        <div className="pt-28"> Successful Authentication </div>
      </main>
    </SearchWrapper>
  );
}
