import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { useIntl } from "react-intl";

interface Auth0ProviderWithNavigateProps {
  children: ReactNode;
}

// You can expand this type based on the shape of the appState you use
interface AppState {
  returnTo?: string;
}

export const Auth0ProviderWithNavigate: React.FC<
  Auth0ProviderWithNavigateProps
> = ({ children }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const domain: string =
    process.env.NODE_ENV === "production"
      ? "jobcrawls.eu.auth0.com"
      : "devjobcrawls.eu.auth0.com";

  const clientId: string =
    process.env.NODE_ENV === "production"
      ? "NMNYNUSqhL4QF57m1ROKxRfKeCfcZgId"
      : "164ND2FkmoLw9quY9ulNvNbFGsIY0uEv";

  const redirectUri: string =
    process.env.NODE_ENV === "production"
      ? "https://jobcrawls.com/" + intl.locale + "/callback"
      : "http://localhost:3000/" + intl.locale + "/callback";

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
