export function addToSavedJobs(url: string): void {
  const existingJobURLs: string[] =
    JSON.parse(localStorage.getItem("savedJobs") ?? "[]") ?? [];
  const newJobsURLsList: string[] = [...existingJobURLs, url];
  localStorage.setItem("savedJobs", JSON.stringify(newJobsURLsList));
}

export function setSavedJobs(jobs: Array<string>) {
  localStorage.setItem("savedJobs", JSON.stringify(jobs));
}
