import * as React from "react";
import { FunctionComponent } from "react";
import { useInstantSearch } from "react-instantsearch";
import { getCategorySEOText, searchTree } from "../searchTree";

interface QuickSearchBannerProps {}

/* Takes-in the facet we want to set and an array of values we want to set for it and updates the search.
 * The children prop is the actual content of the button */
export const SEOText: FunctionComponent<QuickSearchBannerProps> = (props) => {
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const SEOText = getCategorySEOText(indexUiState.query);
  // TODO:: Add the other parts of the search to the title, at least the important ones like location so it's like jobs in "title + location" or "industry + location"
  // const title = indexUiState.query + indexUiState.refinementList && indexUiState.refinementList.location ? ' Jobs in indexUiState.refinementList.locations' : null
  const title = indexUiState.query;

  return SEOText ? (
    <div className="hidden xl:block text-black">
      <h1 className="text-2xl">{title}</h1>
      <p className="text-sm text-gray-700">{SEOText}</p>
    </div>
  ) : null;
};
