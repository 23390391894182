import { useAuth0 } from "@auth0/auth0-react";
import { FormattedMessage } from "react-intl";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        Loading...
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="pt-20 bg-white dark:bg-gray-700 min-h-screen flex items-center justify-center p-4">
      {/* Card Container */}
      <div className="max-w-md w-full bg-white rounded-lg shadow-md overflow-hidden">
        {/* Top Image (Profile Picture) */}
        {user.picture ? (
          <img
            src={user.picture}
            alt={user.name || "User Avatar"}
            className="h-56 w-full object-cover"
          />
        ) : (
          <div className="h-56 w-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-500">No Profile Image</span>
          </div>
        )}

        {/* User Details */}
        <div className="p-6">
          {/* Name */}
          {user.name && (
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              {user.name}
            </h2>
          )}

          {/* Email */}
          {user.email && (
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Email:</span> {user.email}
            </p>
          )}

          {/* Locale */}
          {user.locale && (
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Locale:</span> {user.locale}
            </p>
          )}

          {/* Optional extra fields if your user object has them */}
          {user.nickname && (
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Nickname:</span> {user.nickname}
            </p>
          )}

          {/* CTA Button */}
          <div className="mt-6">
            <button className="bg-amber-300 hover:bg-amber-400 text-gray-800 font-semibold px-4 py-2 rounded">
              <FormattedMessage
                id="profile.get_in_touch"
                defaultMessage="Get In Touch"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
