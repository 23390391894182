import { JobSearchParams } from "../../types";
import { joinOr, toKebabCase } from "../helpers.ts";

/**
 * Builds a Finnish SEO path.
 *
 * English equivalents:
 * - "find"  => "etsi"
 * - "jobs"  => "tyopaikat"
 * - "in"    => "kohteessa"
 * - "page"  => "sivu"
 */
export function buildSeoPath(
  params: JobSearchParams,
  locale: string | undefined
): string {
  const {
    remoteness,
    normalisedRoleLevel,
    requiredLanguages,
    industry,
    company,
    query,
    locations,
    page,
  } = params;

  const tokens: string[] = ["etsi"]; // instead of "find"

  // 1) remoteness
  if (remoteness && remoteness.length > 0) {
    tokens.push(joinOr(remoteness, locale));
  }

  // 2) normalisedRoleLevel
  if (normalisedRoleLevel && normalisedRoleLevel.length > 0) {
    tokens.push(joinOr(normalisedRoleLevel, locale));
  }

  // 3) requiredLanguages
  if (requiredLanguages && requiredLanguages.length > 0) {
    tokens.push(joinOr(requiredLanguages, locale));
  }

  // 4) industry
  if (industry && industry.length > 0) {
    tokens.push(joinOr(industry, locale));
  }

  // 5) company
  if (company && company.length > 0) {
    tokens.push(joinOr(company, locale));
  }

  // 6) query
  if (query && query.trim()) {
    tokens.push(toKebabCase(query.trim()));
  }

  // 7) "tyopaikat" instead of "jobs"
  tokens.push("tyopaikat");

  // 8) If we have locations: "kohteessa" + joined-locations
  if (locations && locations.length > 0) {
    tokens.push("kohteessa");
    tokens.push(joinOr(locations, locale));
  }

  // 9) Page
  if (page !== undefined && page > 1) {
    tokens.push("sivu");
    tokens.push(`${page}`);
  }

  return "/" + tokens.join("-");
}
