import { useEffect } from "react";
import {
  useLocation,
  Location,
  useNavigate,
  useParams,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import RoutesCenter from "./routes/RoutesCenter";
import * as Sentry from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";
import { defaultLocale, supportedLocales } from "./i18n";
import { Auth0ProviderWithNavigate } from "./auth/auth0-provider-with-navigate.tsx";
import { Messages } from "./types.ts";
import { loadLocaleData } from "./generateURLs/helpers.ts";

Sentry.init({
  dsn: "https://f433b489c9378cd799e2405b4052bada@o4508105967140864.ingest.de.sentry.io/4508105968844880",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost" /* /^https:\/\/yourserver\.io\/api/ */],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const { locale } = useParams();
  const navigate = useNavigate();

  // Validate or fallback the locale
  useEffect(() => {
    if (!locale) {
      // No locale provided, redirect to default
      navigate(`/${defaultLocale}`, { replace: true });
    } else if (!supportedLocales.includes(locale)) {
      // Unsupported locale, fallback to default
      navigate(`/${defaultLocale}`, { replace: true });
    }
  }, [locale, navigate]);
  const location: Location = useLocation();
  let lang: string = location.pathname.substring(1, 3);
  lang = lang === "fi" ? lang : lang === "sv" ? lang : "en";
  const messages: Messages = loadLocaleData(lang);

  return (
    <IntlProvider messages={messages} locale={`${lang}`} defaultLocale="en">
      <Auth0ProviderWithNavigate>
        <HelmetProvider>
          <div className="App">
            <RoutesCenter />
          </div>
        </HelmetProvider>
      </Auth0ProviderWithNavigate>
    </IntlProvider>
  );
}

export default App;
