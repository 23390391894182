import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { localisedRouteGenerator } from "../../../i18n/routes/routeGenerator";

const Logo = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <>
      <img
        onClick={() =>
          navigate(localisedRouteGenerator(false, intl, "route.home"))
        }
        className="hidden sm:block cursor-pointer"
        src={"/logo_horizontal.png"}
        height="100"
        width="100"
        alt="Logo"
      />
    </>
  );
};

export default Logo;
