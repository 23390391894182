import { history } from "instantsearch.js/es/lib/routers";
import { INDEX_NAME } from "../constants";
import { buildSeoPath as ENBuildPath } from "./en/buildSeoPath.ts";
import { parseSeoPath as ENParsePath } from "./en/parseSeoPath.ts";
import { buildSeoPath as SVBuildPath } from "./sv/buildSeoPath.ts";
import { parseSeoPath as SVParsePath } from "./sv/parseSeoPath.ts";
import { buildSeoPath as FIBuildPath } from "./fi/buildSeoPath.ts";
import { parseSeoPath as FIParsePath } from "./fi/parseSeoPath.ts";
import { extractLocaleSegment, reconstructURL } from "./helpers.ts";

export const localeHandling: Record<
  string,
  { buildSeoPath: Function<string>; parseSeoPath: Function<string> }
> = {
  en: {
    buildSeoPath: ENBuildPath,
    parseSeoPath: ENParsePath,
  },
  sv: {
    buildSeoPath: SVBuildPath,
    parseSeoPath: SVParsePath,
  },
  fi: {
    buildSeoPath: FIBuildPath,
    parseSeoPath: FIParsePath,
  },
};

/**
 * Describes the fields we store in routeState.
 * Adjust types as needed (e.g., string vs string[]).
 */
interface RouteState {
  query?: string;
  page?: number;
  locations?: string[];
  requiredLanguages?: string[];
  remoteness?: string[];
  industry?: string[];
  company?: string[];
  normalisedRoleLevel?: string[];
}

/**
 * This interface represents the shape of the object returned by parseSeoPath.
 * Customize further if parseSeoPath may return different types.
 */
interface ParsedSeo {
  query?: string;
  page?: number;
  locations?: string[];
  requiredLanguages?: string[];
  remoteness?: string[];
  industry?: string[];
  company?: string[];
  normalisedRoleLevel?: string[];
}

/**
 * `extractLocaleSegment` presumably returns a string `locale` and the remaining path as `remainder`.
 * Adjust if your actual function returns something else.
 */
interface ExtractedLocale {
  locale?: string;
  remainder: string;
}

/**
 * Minimal type for the parameter object passed to `createURL` and `parseURL`.
 * `qsModule` could be typed more specifically if known.
 */
interface RouterParams<T = unknown> {
  qsModule: T;
  routeState: RouteState;
  location: Location; // The standard browser Location object
}

/**
 * Minimal type for the parameter object passed to `parseURL`.
 */
interface ParseURLParams<T = unknown> {
  qsModule: T;
  location: Location;
}

/**
 * The shape of your UI State in InstantSearch.
 * We'll define `refinementList` as an object with optional keys.
 * Customize further as needed.
 */
interface InstantSearchUiState {
  query?: string;
  page?: number;
  refinementList?: {
    requiredLanguages?: string[];
    location?: string[];
    remoteness?: string[];
    ["company.industry"]?: string[];
    companyId?: string[];
    normalisedRoleLevel?: string[];
  };
}

/**
 * If you use the `uiState` as a dictionary with multiple indices,
 * you can represent it like this: Record<string, InstantSearchUiState>.
 */
type UiStateMapping = Record<string, InstantSearchUiState>;
// TODO:: rewrite this to use react router functionality instead of rewriting the history
export const routing = (navigate) => {
  return {
    router: history({
      // windowTitle(routeState): string {
      //   const { query, locations } = routeState;
      //   console.log(routeState)
      //   // TODO:: Make this intl compatible and change other page metadata
      //   const queryTitle = query
      //     ? `"${query}"`
      //     : "JobCrawls.com";
      //
      //   if (locations && Array.isArray(locations) && locations.length > 0) {
      //     // Join array for display, or handle it however you want
      //     return `${locations.join(", ")} – ${queryTitle}`;
      //   }
      //
      //   return queryTitle;
      // },

      createURL({ routeState, location }: RouterParams): string {
        if (
          !routeState.query &&
          !routeState.locations &&
          !routeState.requiredLanguages &&
          !routeState.remoteness &&
          !routeState.industry &&
          !routeState.company &&
          !routeState.normalisedRoleLevel
        ) {
          return reconstructURL(
            location.protocol,
            location.host,
            undefined,
            location.pathname
          );
        }
        const { locale }: ExtractedLocale = extractLocaleSegment(
          location.pathname
        );
        // TODO:: Change conjunctions and disjunctions to be locale dependent
        const buildSeoPath = localeHandling[locale ?? "en"].buildSeoPath;

        const SEOPath = buildSeoPath(
          {
            query: routeState.query,
            page: routeState.page,
            locations: routeState.locations,
            requiredLanguages: routeState.requiredLanguages,
            remoteness: routeState.remoteness,
            industry: routeState.industry,
            company: routeState.company,
            normalisedRoleLevel: routeState.normalisedRoleLevel,
          },
          locale
        );

        return reconstructURL(
          location.protocol,
          location.host,
          locale,
          SEOPath
        );
      },
      parseURL({ location }: ParseURLParams): RouteState {
        const { locale, remainder }: ExtractedLocale = extractLocaleSegment(
          location.pathname
        );
        // console.log("parseURL", locale, remainder);
        const parseSeoPath = localeHandling[locale ?? "en"].parseSeoPath;
        const {
          query,
          page,
          locations,
          requiredLanguages,
          industry,
          remoteness,
          company,
          normalisedRoleLevel,
        }: ParsedSeo = parseSeoPath(remainder, locale);

        return {
          query,
          page,
          locations,
          requiredLanguages,
          industry,
          remoteness,
          company,
          normalisedRoleLevel,
        };
      },
      // push(url: string): void {
      //   // TODO:: Figure out how to overwrite the URL here
      //   navigate(url, { replace: true })
      // },
    }),
    stateMapping: {
      stateToRoute(uiState: UiStateMapping): RouteState {
        // Grab the UI state for the relevant index
        const indexUiState = uiState[INDEX_NAME] || {};

        return {
          query: indexUiState.query,
          page: indexUiState.page,
          requiredLanguages: indexUiState.refinementList?.requiredLanguages,
          locations: indexUiState.refinementList?.location,
          remoteness: indexUiState.refinementList?.remoteness,
          industry: indexUiState.refinementList?.["company.industry"],
          company: indexUiState.refinementList?.companyId,
          normalisedRoleLevel: indexUiState.refinementList?.normalisedRoleLevel,
        };
      },

      routeToState(routeState: RouteState): UiStateMapping {
        return {
          [INDEX_NAME]: {
            query: routeState.query,
            page: routeState.page,
            refinementList: {
              requiredLanguages: routeState.requiredLanguages,
              location: routeState.locations,
              remoteness: routeState.remoteness,
              "company.industry": routeState.industry,
              companyId: routeState.company,
              normalisedRoleLevel: routeState.normalisedRoleLevel,
            },
          },
        };
      },
    },
  };
};
