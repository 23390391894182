import * as React from "react";
import { IntlShape, useIntl } from "react-intl";
import { SearchWrapper } from "../../components/SearchWrapper";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function CoolFinnishTechCompaniesEnglish() {
  const intl: IntlShape = useIntl();
  return (
    <SearchWrapper>
      <main className="bg-gray-100 text-gray-900">
        {/*<section className="flex flex-col lg:flex-row w-full lg:px-10 py-24">*/}
        {/*  <article className="w-4/5 lg:w-custom mb-10 lg:px-4 px-6 py-10 text-primary-dark shadow-md p-4 rounded-2xl">*/}
        {/*    <h2 className="pb-4 flex font-bold border-b border-gray-300">*/}
        {/*      <span className="text-4xl">*/}
        {/*        Cool Finnish Tech Companies that Work in English*/}
        {/*      </span>*/}
        {/*    </h2>*/}
        {/*    <div className="py-8">*/}
        {/*      <Link to="/en/?text_search_index[refinementList][company.name][0]=Yousician"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Yousician</p></Link>*/}
        {/*      <p className="text-lg">Yousician does cool software for people to learn instruments, with an office on the main Hakaniemi square beside the famous Hakaniemi market hall</p>*/}
        {/*    </div>*/}
        {/*    <Link to="/en/?text_search_index[refinementList][company.name][0]=Saidot"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Saidot</p></Link>*/}
        {/*    <Link to="/en/?text_search_index[refinementList][company.name][0]=Wolt"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Wolt</p></Link>*/}
        {/*    <Link to="/en/?text_search_index[refinementList][company.name][0]=Supercell"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Supercell</p></Link>*/}
        {/*    <Link to="/en/?text_search_index[refinementList][company.name][0]=Rovio"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Rovio</p></Link>*/}
        {/*    <Link to="/en/?text_search_index[refinementList][company.name][0]=Awake.ai"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Awake.ai</p></Link>*/}
        {/*    <Link to="/en/?text_search_index[refinementList][company.name][0]=Skyforce"><p className="font-medium text-blue-600 dark:text-blue-500 hover:underline text-lg">Skyforce</p></Link>*/}
        {/*  </article>*/}
        {/*</section>*/}
        <header className="bg-blue-600 text-white py-6 pt-32">
          <Helmet>
            <title>
              JobCrawls.com | Top Tech Companies in Finland Working in English
            </title>
            <meta
              name="description"
              content="Discover the coolest tech companies in Finland like Yousician, Saidot, Wolt, Supercell, Rovio, Awake.ai, and Skyforce that embrace English as their working language."
            />
            <meta
              name="keywords"
              content="tech companies in Finland, Yousician, Saidot, Wolt, Supercell, Rovio, Awake.ai, Skyforce, English-friendly companies in Finland"
            />
            <meta name="author" content="JobCrawls.com" />
          </Helmet>
          <h1 className="text-center text-4xl font-bold">
            Top Tech Companies in Finland Working in English
          </h1>
          <p className="text-center mt-2">
            Explore how Finland’s coolest tech companies combine innovation and
            international culture.
          </p>
        </header>

        <main className="max-w-4xl mx-auto py-8 px-4">
          <section className="mb-8">
            <p className="text-lg">
              Finland is home to some of the most innovative and exciting tech
              companies in the world. From gaming powerhouses to AI-driven
              solutions, these companies are making waves globally. Many of them
              operate in English, welcoming international talent and fostering a
              vibrant multicultural work environment. Here's a look at some of
              Finland’s coolest tech companies you should know about.
            </p>
          </section>

          <section id="yousician" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Yousician">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Yousician
              </h2>
            </Link>
            <p className="text-lg">
              Yousician is revolutionizing music education with its app that
              makes learning an instrument fun and interactive. Based in
              Helsinki, Yousician’s platform uses cutting-edge technology to
              provide real-time feedback on your playing. The company has a
              global reach, and its team thrives in an English-speaking
              environment, making it a great choice for international
              professionals passionate about music and technology.
            </p>
          </section>

          <section id="saidot" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Saidot">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">Saidot</h2>
            </Link>
            <p className="text-lg">
              Saidot focuses on bringing transparency and accountability to
              artificial intelligence. Their innovative platform helps
              organizations build trustworthy AI systems. Headquartered in
              Espoo, Saidot's English-speaking team works on global AI
              governance challenges, making it a fantastic place for AI
              enthusiasts eager to make an ethical impact.
            </p>
          </section>

          <section id="wolt" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Wolt">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">Wolt</h2>
            </Link>
            <p className="text-lg">
              Wolt has become a household name for food delivery in Finland and
              beyond. Operating in 23+ countries, Wolt combines technology and
              logistics to deliver meals quickly and efficiently. The
              Helsinki-based company’s diverse, English-friendly workplace
              attracts top talent from around the world who love solving
              real-world challenges.
            </p>
          </section>

          <section id="supercell" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Supercell">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Supercell
              </h2>
            </Link>
            <p className="text-lg">
              Known for hits like <em>Clash of Clans</em> and{" "}
              <em>Brawl Stars</em>, Supercell is a gaming giant headquartered in
              Helsinki. The company’s success stems from its independent,
              small-team culture where employees take ownership of their
              projects. Supercell’s English-first policy and global mindset make
              it a dream employer for creative gaming professionals worldwide.
            </p>
          </section>

          <section id="rovio" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Rovio">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">Rovio</h2>
            </Link>
            <p className="text-lg">
              Rovio is the creator of the globally beloved <em>Angry Birds</em>{" "}
              franchise. With a focus on mobile gaming and entertainment, this
              Espoo-based company combines creativity and technical excellence.
              Rovio’s English-speaking environment ensures international talent
              can contribute seamlessly to its global success.
            </p>
          </section>

          <section id="awake-ai" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Awake.ai">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Awake.ai
              </h2>
            </Link>
            <p className="text-lg">
              Awake.ai is pioneering the future of smart ports and logistics.
              Their platform leverages AI and IoT to optimize port operations,
              reducing costs and environmental impact. Based in Turku,
              Awake.ai’s English-speaking team is shaping the future of maritime
              tech, making it a great destination for tech enthusiasts seeking
              impactful projects.
            </p>
          </section>

          <section id="skyforce" className="mb-12">
            <Link to="/en/?text_search_index[refinementList][companyId][0]=Skyforce">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Skyforce
              </h2>
            </Link>
            <p className="text-lg">
              Skyforce specializes in drone technology and aerial solutions,
              offering cutting-edge services across various industries. With its
              base in Finland, Skyforce’s innovative solutions are supported by
              a multicultural, English-friendly team, perfect for tech-savvy
              individuals fascinated by the possibilities of drones.
            </p>
          </section>
        </main>

        <footer className="bg-gray-800 text-white py-6">
          <p className="text-center">
            &copy; 2024 Workly.ai - Your gateway to exciting tech opportunities
            in Finland and beyond.
          </p>
        </footer>
      </main>
    </SearchWrapper>
  );
}
