import React from "react";
import { Helmet } from "react-helmet-async";
import { SearchWrapper } from "../components/SearchWrapper.tsx";

const TermsOfService: React.FC = () => {
  return (
    <SearchWrapper>
      <div className="bg-white pt-32 px-4 max-w-3xl mx-auto text-gray-700">
        <Helmet>
          <title>Terms of Service | JobCrawls</title>
          <meta
            name="description"
            content="JobCrawls.com Terms of Service outlining the rules and regulations for using our platform."
          />
        </Helmet>

        <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>

        <p className="mb-4">
          Welcome to JobCrawls! By accessing and using our website, you agree to
          be bound by these Terms of Service. Please read them carefully before
          using our platform. We are a Finnish company, and these terms are
          governed by applicable Finnish and EU laws.
        </p>

        <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By using our website and services, you acknowledge that you have read,
          understood, and agree to be bound by these Terms of Service, as well
          as our Privacy Policy. If you do not agree with any part of these
          terms, you should discontinue use of our services.
        </p>

        <h2 className="text-2xl font-semibold mb-2">2. User Obligations</h2>
        <p className="mb-4">
          You agree to use JobCrawls in compliance with all applicable laws and
          regulations. You must not use our site in a way that violates the
          rights of others or restricts or inhibits anyone else’s use and
          enjoyment of the site.
        </p>

        <h2 className="text-2xl font-semibold mb-2">
          3. Intellectual Property
        </h2>
        <p className="mb-4">
          All content, trademarks, and data on this site, including text,
          graphics, images, and software, are owned by JobCrawls or its
          licensors. You may not use or reproduce any portion of the site’s
          content without written permission from us.
        </p>

        <h2 className="text-2xl font-semibold mb-2">
          4. Limitation of Liability
        </h2>
        <p className="mb-4">
          JobCrawls and its affiliates will not be liable for any indirect,
          incidental, special, or consequential damages arising out of or in any
          way connected with the use of our website or services. In all cases,
          our liability is limited to the maximum extent permitted by Finnish
          and EU law.
        </p>

        <h2 className="text-2xl font-semibold mb-2">
          5. Modifications to the Service
        </h2>
        <p className="mb-4">
          We reserve the right to modify, suspend, or discontinue any aspect of
          our service at any time without prior notice. We will not be liable
          should we exercise this right.
        </p>

        <h2 className="text-2xl font-semibold mb-2">6. Termination</h2>
        <p className="mb-4">
          We may terminate or suspend your access to our website immediately,
          without prior notice, if we determine that you have breached these
          Terms of Service or for any other reason deemed necessary.
        </p>

        <h2 className="text-2xl font-semibold mb-2">
          7. Changes to These Terms
        </h2>
        <p className="mb-4">
          We may update these Terms of Service from time to time. When we do, we
          will post the revised terms on this page. Your continued use of our
          site after any changes constitutes your acceptance of the new terms.
        </p>

        <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms of Service, please contact
          us at:
          <a
            href="mailto:spinaadbusiness@gmail.com"
            className="text-blue-600 underline ml-1"
          >
            spinaadbusiness@gmail.com
          </a>
          .
        </p>
      </div>
    </SearchWrapper>
  );
};

export default TermsOfService;
