import { IntlShape } from "react-intl";

export const localisedRouteGenerator = (
  withoutLocale: boolean,
  intl: IntlShape,
  route: string,
  paramsString?: string
): string => {
  const descriptor = { id: route };

  return `${withoutLocale ? "" : "/" + intl.locale}${intl.formatMessage(
    descriptor
  )}${paramsString ? "?" + paramsString : ""}`;
};
