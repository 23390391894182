import { localeHandling } from "./generateURLs.ts";
import { Messages } from "../types.ts";
import fi from "../i18n/compiled/fi.json";
import sv from "../i18n/compiled/sv.json";
import en from "../i18n/compiled/en.json";

export function loadLocaleData(locale: string): Messages {
  switch (locale) {
    case "fi":
      return fi;
    case "sv":
      return sv;
    default:
      return en;
  }
}

export function reconstructURL(
  protocol: string,
  host: string,
  locale: string | undefined,
  path: string
): string {
  return protocol + "//" + host + (locale ? `/${locale}` : "") + path;
}

export const changeLanguage = (
  currentLocale: string,
  newLocale: string,
  location: Location
): string => {
  const currentURL = location.pathname;
  // console.log("changing locale", currentLocale, newLocale, currentURL)
  // 2) If there's no direct match, parse & rebuild
  if (!currentLocale || !newLocale) {
    // console.log('no currentLocale or newLocale')
    // Edge case if we don't have localeHandling for these locales
    return reconstructURL(location.protocol, location.host, newLocale, "");
  }

  const { locale, remainder } = extractLocaleSegment(currentURL);
  const currentLocaleHandling = localeHandling[currentLocale];
  const newLocaleHandling = localeHandling[newLocale];
  const newMessages = loadLocaleData(newLocale);
  const currentMessages = loadLocaleData(currentLocale);

  // 1) Check if currentURL is in currentMessages
  //    e.g. "route.search" => "/fi/haku"
  console.log(currentLocale, newLocale, "remainder", "/" + remainder, locale);
  const foundKey = Object.keys(currentMessages).find(
    // Adding back slash because URLs have thos
    (key) => currentMessages[key] === "/" + remainder
  );
  if (foundKey) {
    // console.log('found match', foundKey)
    // We found a route key that matches currentURL
    // e.g., foundKey = "route.search"
    const newUrl = newMessages[foundKey];
    if (newUrl) {
      // The new locale has this route defined => just return it
      return reconstructURL(
        location.protocol,
        location.host,
        newLocale,
        newUrl
      );
    }
    // If it's missing, fall through to parse & build
  }

  const parsed = currentLocaleHandling.parseSeoPath(remainder, locale);
  console.log("found parse", currentLocale, parsed);
  const built = newLocaleHandling.buildSeoPath(parsed, locale);
  console.log("built new url", built);
  return reconstructURL(location.protocol, location.host, newLocale, built);
};

/**
 * Convert an arbitrary string into a "kebab-case" or slug-like string:
 *
 * Examples:
 *    "Helsinki, Finland" -> "Helsinki-Finland"
 *    "social media"     -> "social-media"
 */
export function toKebabCase(str: string): string {
  return str
    .replace(/,/g, "") // remove commas
    .trim()
    .replace(/\s+/g, "-") // spaces -> hyphens
    .replace(/-+/g, "-") // collapse multiple hyphens
    .replace(/[^\w-]/g, ""); // remove any other non-alphanumeric chars (optional)
}

/**
 * Convert a kebab-case location string back to a "City, Country" style
 * if it looks like 2 words (e.g. "Helsinki-Finland" => "Helsinki, Finland").
 * Otherwise just replaces '-' with space.
 */
export function fromKebabCaseToLocation(str: string): string {
  const spaced = str.replace(/-/g, " ");
  const parts = spaced.split(" ");
  if (parts.length === 2) {
    return parts[0] + ", " + parts[1];
  }
  return spaced;
}

/**
 * Join an array of strings with '-or-' for disjunction in the URL.
 * e.g. ["remote", "hybrid"] => "remote-or-hybrid"
 */
export function joinOr(arr: string[], locale: string | undefined): string {
  const disjunctor =
    locale === "fi" ? "-tai-" : locale === "sv" ? "-eller-" : "-or-";
  return arr.map(toKebabCase).join(disjunctor);
}

/**
 * Split a token on '-or-' to get an array of strings,
 * reversing our joinOr().
 */
export function splitOr(str: string, locale: string | undefined): string[] {
  const disjunctor =
    locale === "fi" ? "-tai-" : locale === "sv" ? "-eller-" : "-or-";
  return str.split(disjunctor).map((s) => s.trim());
}

/**
 * Extracts an optional locale segment (e.g. "en") from the very start of the path.
 * Returns { locale?: string, remainder: string }.
 */
export function extractLocaleSegment(url: string): {
  locale?: string;
  remainder: string;
} {
  // 1) Remove leading slash (if any)
  if (url.startsWith("/")) {
    url = url.slice(1);
  }

  // If the entire URL is empty, there's no locale, remainder is ""
  if (!url) {
    return { remainder: "" };
  }

  // 2) Split into segments by "/"
  //   e.g. "en/find-remote-jobs" => ["en", "find-remote-jobs"]
  const [firstSegment, ...rest] = url.split("/");

  // 3) Check if the first segment matches a locale pattern, e.g. en, fi, sv
  //    This regex means: two letters.
  //    Adjust if you want to allow "en-US", "pt-BR", or more flexible patterns.
  const localeRegex = /^[A-Za-z]{2}$/;

  if (localeRegex.test(firstSegment)) {
    // We found a locale
    const locale = firstSegment;
    // The remainder is everything else after that
    const remainder = rest.join("/");
    return { locale, remainder };
  } else {
    // The first segment wasn't a locale, so put it back together with the rest
    const remainder = [firstSegment, ...rest].join("/");
    return { remainder };
  }
}
