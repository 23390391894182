// src/i18n/index.js
import messages_en from "./compiled/en.json";
import messages_sv from "./compiled/sv.json";
import messages_fi from "./compiled/fi.json";

export const messages = {
  en: messages_en,
  sv: messages_sv,
  fi: messages_fi,
};

// Option 1: Hardcode the list of locales
export const supportedLocales = ["en", "fi", "sv"];

// Option 2: Dynamically derive from messages keys
// (uncomment if you prefer dynamic retrieval)
// export const supportedLocales = Object.keys(messages);

export const defaultLocale = "en";
