import React from "react";
import { Helmet } from "react-helmet-async";
import { SearchWrapper } from "../components/SearchWrapper.tsx";

const Privacy: React.FC = () => {
  return (
    <SearchWrapper>
      <div className="bg-white pt-32 px-4 max-w-3xl mx-auto text-gray-700">
        <Helmet>
          <title>Privacy Policy | JobCrawls</title>
          <meta
            name="description"
            content="JobCrawls.com Privacy Policy describing how we handle user data for analytics as a Finnish company."
          />
        </Helmet>

        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          At JobCrawls, we respect your privacy and are committed to protecting
          your personal data. We are a Finnish company and comply with
          applicable Finnish and EU data protection regulations.
        </p>

        <h2 className="text-2xl font-semibold mb-2">Data Collection and Use</h2>
        <p className="mb-4">
          The primary purpose of collecting your data is for analytics, helping
          us understand how users interact with our site in order to enhance
          your experience. We only collect data that is necessary to improve our
          website and services.
        </p>

        <h2 className="text-2xl font-semibold mb-2">Analytics</h2>
        <p className="mb-4">
          We may use third-party analytics tools to collect information about
          how you use our website, including the pages you visit and the time
          you spend on them. This data is used solely for improving our
          services.
        </p>

        <h2 className="text-2xl font-semibold mb-2">
          Data Protection and Retention
        </h2>
        <p className="mb-4">
          We implement appropriate security measures to protect your data from
          unauthorized access, alteration, disclosure, or destruction. We retain
          data as long as it is necessary to fulfill the purposes described in
          this policy or as required by law.
        </p>

        <h2 className="text-2xl font-semibold mb-2">Your Rights</h2>
        <p className="mb-4">
          As a user in the EU, you have the right to request access to,
          correction, or deletion of your personal data, as well as the right to
          restrict or object to certain types of processing. For more
          information or to exercise these rights, please contact us at
          <a
            href="mailto:spinaadbusiness@gmail.com"
            className="text-blue-600 underline"
          >
            spinaadbusiness@gmail.com
          </a>
          .
        </p>

        <h2 className="text-2xl font-semibold mb-2">Changes to This Policy</h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. Changes will be
          posted on this page and become effective immediately upon posting. If
          you have any questions or concerns, please contact us at the email
          above.
        </p>
      </div>
    </SearchWrapper>
  );
};

export default Privacy;
