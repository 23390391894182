import { joinOr, toKebabCase } from "../helpers.ts";
import { JobSearchParams } from "../../types.ts";

export function buildSeoPath(
  params: JobSearchParams,
  locale: string | undefined
): string {
  // Extract parameters
  const {
    remoteness,
    normalisedRoleLevel,
    requiredLanguages,
    industry,
    company,
    query,
    locations,
    page,
  } = params;

  // We'll build an array of tokens in the order we want them to appear.
  // We'll conditionally skip tokens that are empty/undefined.
  const tokens: string[] = ["find"];

  // 1) remoteness
  if (remoteness && remoteness.length > 0) {
    tokens.push(joinOr(remoteness, locale));
  }

  // 2) normalisedRoleLevel
  if (normalisedRoleLevel && normalisedRoleLevel.length > 0) {
    tokens.push(joinOr(normalisedRoleLevel, locale));
  }

  // 3) requiredLanguages
  if (requiredLanguages && requiredLanguages.length > 0) {
    tokens.push(joinOr(requiredLanguages, locale));
  }

  // 4) industry
  if (industry && industry.length > 0) {
    tokens.push(joinOr(industry, locale));
  }

  // 5) company
  if (company && company.length > 0) {
    tokens.push(joinOr(company, locale));
  }

  // 6) query
  if (query && query.trim()) {
    tokens.push(toKebabCase(query.trim()));
  }

  // 7) the literal "jobs"
  tokens.push("jobs");

  // 8) if we have locations, do "in" + joined-locations
  if (locations && locations.length > 0) {
    tokens.push("in");
    tokens.push(joinOr(locations, locale));
  }

  // 9) if we have a page, do "page" + page number
  if (page !== undefined && page !== null && page > 1) {
    // We'll only show "page-x" if page > 1 for human-friendly reasons,
    // but you can always show it if you prefer.
    tokens.push("page");
    tokens.push(`${page}`);
  }

  // Join them all with "-"
  return "/" + tokens.join("-");
}
