import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";
import Avatar from "../Avatar";
import { User } from "@auth0/auth0-spa-js";
import { useAuth0 } from "@auth0/auth0-react";
import { localisedRouteGenerator } from "../../../i18n/routes/routeGenerator";
import { FormattedMessage, useIntl } from "react-intl";
import ReactGA from "react-ga4";
import { changeLanguage } from "../../../generateURLs/helpers.ts";

interface UserMenuProps {
  currentUser?: User | null;
}

const UserMenu: React.FC<UserMenuProps> = ({ currentUser }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { loginWithRedirect, logout } = useAuth0();

  const handleLogin = async () => {
    // Send a custom event
    ReactGA.event({
      category: "interactions",
      action: "clickLogin",
      nonInteraction: false, // optional, true/false
    });
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((value) => {
      ReactGA.event({
        category: "interactions",
        action: value ? "clickOpenUserMenu" : "clickCloseUserMenu",
        nonInteraction: false, // optional, true/false
      });
      return !value;
    });
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="relative bg-amber-300 text-black" ref={ref}>
      <div className="flex flex-row items-center gap-0 sm:gap-3">
        <Link to={localisedRouteGenerator(false, intl, "route.blog")}>
          <div
            className="
            hidden
            sm:block
            text-sm
            font-semibold
            py-3
            px-4
            rounded-full
            hover:bg-neutral-100
            transition
            cursor-pointer
          "
          >
            <FormattedMessage
              id="usermenu.blog"
              description="Link to the blog from the user menu in the header"
              defaultMessage="Blog"
            />
          </div>
        </Link>
        <div
          onClick={toggleOpen}
          className="
          p-4
          lg:py-1
          lg:px-2
          border-[1px] 
          border-black
          flex 
          flex-row 
          items-center 
          gap-3 
          rounded-full 
          cursor-pointer 
          hover:shadow-md 
          transition
          "
        >
          <AiOutlineMenu />
          <div className="hidden lg:block">
            <Avatar src={currentUser?.picture} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className="
            absolute 
            rounded-xl 
            shadow-md
            w-[40vw]
            lg:w-4/5
            bg-white
            overflow-hidden 
            right-0 
            top-12 
            text-sm
          "
        >
          <div className="flex flex-col cursor-pointer">
            <div className="flex transition font-semibold justify-between">
              <Link to={changeLanguage(intl.locale, "fi", window.location)}>
                <button
                  onClick={() => {
                    ReactGA.event({
                      category: "interactions",
                      action: "clickChangeLanguageFI",
                      nonInteraction: false, // optional, true/false
                    });
                  }}
                  className="px-3 py-2 hover:bg-neutral-100"
                >
                  {"FI"}
                </button>
              </Link>

              <Link to={changeLanguage(intl.locale, "sv", window.location)}>
                <button
                  onClick={() => {
                    ReactGA.event({
                      category: "interactions",
                      action: "clickChangeLanguageSV",
                      nonInteraction: false, // optional, true/false
                    });
                  }}
                  className="px-2 py-2 hover:bg-neutral-100"
                >
                  {"SV"}
                </button>
              </Link>
              <Link to={changeLanguage(intl.locale, "en", window.location)}>
                <button
                  onClick={() => {
                    ReactGA.event({
                      category: "interactions",
                      action: "clickChangeLanguageEN",
                      nonInteraction: false, // optional, true/false
                    });
                  }}
                  className="px-3 py-2 hover:bg-neutral-100"
                >
                  {"EN"}
                </button>
              </Link>
            </div>
            <hr />
            <MenuItem
              label={intl.formatMessage({
                id: "usermenu.saved",
                description:
                  "Link to the saved jobs page from the user menu in the header",
                defaultMessage: "Saved Jobs",
              })}
              onClick={() =>
                navigate(localisedRouteGenerator(false, intl, "route.saved"))
              }
            />
            <MenuItem
              label={intl.formatMessage({
                id: "usermenu.business",
                description:
                  "Link to the business page from the user menu in the header",
                defaultMessage: "About our Business",
              })}
              onClick={() =>
                navigate(localisedRouteGenerator(false, intl, "route.business"))
              }
            />
            <MenuItem
              label={intl.formatMessage({
                id: "usermenu.terms",
                description:
                  "Link to the terms of service page from the user menu in the header",
                defaultMessage: "Terms of Service",
              })}
              onClick={() =>
                navigate(localisedRouteGenerator(false, intl, "route.terms"))
              }
            />
            <MenuItem
              label={intl.formatMessage({
                id: "usermenu.privacy",
                description:
                  "Link to the privacy policy page from the user menu in the header",
                defaultMessage: "Privacy Policy",
              })}
              onClick={() =>
                navigate(localisedRouteGenerator(false, intl, "route.privacy"))
              }
            />
            {currentUser ? (
              <>
                <MenuItem
                  label={intl.formatMessage({
                    id: "usermenu.profile",
                    description:
                      "Link to the profile page from the user menu in the header",
                    defaultMessage: "My Profile",
                  })}
                  onClick={() =>
                    navigate(
                      localisedRouteGenerator(
                        false,
                        intl,
                        "route.profileOverview"
                      )
                    )
                  }
                />
                <hr />
                <MenuItem
                  label={intl.formatMessage({
                    id: "usermenu.logout",
                    description:
                      "Triggers logout from the user menu in the header",
                    defaultMessage: "Logout",
                  })}
                  onClick={() => handleLogout()}
                />
              </>
            ) : (
              <>
                <MenuItem
                  label={intl.formatMessage({
                    id: "usermenu.login",
                    description:
                      "Triggers login from the user menu in the header",
                    defaultMessage: "Login",
                  })}
                  onClick={handleLogin}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
