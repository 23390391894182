import Profile from "../components/components/Profile";
import { SearchWrapper } from "../components/SearchWrapper.tsx";

export default function ProfileOverview() {
  return (
    <SearchWrapper>
      <div>
        <main>
          <Profile />
        </main>
      </div>
    </SearchWrapper>
  );
}
